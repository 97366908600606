import * as React from "react";
import { useSpring, animated } from "react-spring";

import { useCursorState } from "../context/cursorStateContext";

const HoverStyles = () => {
  const { setCursorState } = useCursorState();

  const [buttonHover, setButtonHover] = React.useState(false);
  const hoverStyles = useSpring({
    color: "black",
    gradient: buttonHover
      ? "linear-gradient(45deg, orange 30%, orangered 90%)"
      : "linear-gradient(45deg, orange 0%, orangered 0%)",
  });
  return (
    <animated.button
      style={{
        cursor: "pointer",
        fontSize: "1em",
        fontWeight: 600,
        fontFamily: "system-ui, sans-serif",
        lineHeight: 1,
        border: "1px solid black",
        padding: "1em 2em",
        borderRadius: "2em",
        color: hoverStyles.color,
        background: hoverStyles.gradient,
      }}
      onFocus={() => setButtonHover(true)}
      onBlur={() => setButtonHover(false)}
      onMouseEnter={() => {
        setButtonHover(true);
        setCursorState("clickable");
      }}
      onMouseLeave={() => {
        setButtonHover(false);
        setCursorState("normal");
      }}
    >
      Hover styles + useCursorState
    </animated.button>
  );
};

export default HoverStyles;
