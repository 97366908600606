import * as React from "react";
import { useSpring, animated, config } from "react-spring";
import { useCursorState } from "../context/cursorStateContext";

const ClickPalindromeAnimation = () => {
  const { setCursorState } = useCursorState();
  const [uselessState, setUselessState] = React.useState(false);
  const palindromeSpring = React.useMemo(() => {
    return {
      from: { progression: 0 },
      progression: uselessState ? 1 : 0,
      config: { ...config.wobbly },
    };
  }, [uselessState]);
  const { progression } = useSpring(palindromeSpring);

  return (
    <animated.div
      style={{
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        cursor: "pointer",
        display: "grid",
        placeContent: "center",
        background:
          "radial-gradient(circle at 25% 25%, #c9c4cf 10%, rebeccapurple)",
        transform: progression
          .to({ range: [0, 0.5, 1], output: [100, 150, 100] })
          .to((progression) => `scale(${progression}%)`),
      }}
      onClick={() => {
        setUselessState(!uselessState);
      }}
      onMouseEnter={() => setCursorState("clickable")}
      onMouseLeave={() => setCursorState("normal")}
    >
      <span style={{ userSelect: "none" }}>Click me</span>
    </animated.div>
  );
};

export default ClickPalindromeAnimation;
