import React from "react";
import { useSpring, animated, config } from "react-spring";

const SVGMorphingSamePaths = () => {
  const [morphFlipped, flipMorph] = React.useState(false);
  const { morph } = useSpring({
    from: { morph: 0 },
    morph: 1,
    reverse: morphFlipped,
    onRest: () => flipMorph(!morphFlipped),
    config: config.slow,
  });

  // const interpolator = interpolate(paths[0], paths[6], {
  //   maxSegmentLength: 10,
  // });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "350px",
        alignItems: "center",
      }}
    >
      <svg
        // width="350"
        height="200"
        viewBox="0 0 200 200"
        // width="286"
        // height="272"
        // viewBox="0 0 286 272"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.path
          d={morph.to({
            // range: [0, 1],
            range: [0, 0.7, 1],
            // range: [0, 0.3, 1],
            output: [
              // circle
              // "M 100,200 C 45,200 0,155 0,100 C 0,45 45,0 100,0 C 155,0 200,45 200,100 C 200,155 155,200 100,200 Z",
              // square
              // "M 0 200 C 0 200 0 0 0 0 C 0 0 200 0 200 0 C 200 0 200 200 200 200 C 200 200 0 200 0 200 Z",
              // rhombus-like
              // "M 100,200 C 100,200 0,100 0,100 C 0,100 100,0 100,0 C 100,0 200,100 200,100 C 200,100 100,200 100,200 Z",

              // Different number of paths these two
              // bars
              // "M 25,77 L 175,77 M 25,123 L 175,123",
              // close
              // "M 47,47 L 153.066,153.066 M 47,153.066 L 153.066,47",

              // Different number of paths these 5
              // triple bars
              "M 25,100 L 175,100 M 25,60 L 175,60 M 25,140 L 175,140",
              // triple bars/close middle.
              // "M 224 100 L 374 100 M 47 47 L 197 47 M 47 153.099 L 197 153.099",
              // close
              // "M 224,100 L 374,100 M 47,47 L 153.066,153.066 M 47,153.099 L 153.066,47.0331",
              // triple bars / dots middle
              "M 25 100 L 25 100 M 175 60 L 175 60 M 175 140 L 175 140",
              // dots
              "M 140 100 L 140 100 M 60 100 L 60 100 M 100 100 L 100 100",
            ],
          })}
          stroke="url(#paint0_linear_2:4)"
          stroke-width="20"
          stroke-linecap="round"
          fill="url(#paint0_linear_2:4)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2:4"
            x1="21"
            y1="-32"
            x2="244"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9AF20" />
            <stop offset="1" stopColor="#FF6060" />
          </linearGradient>
        </defs>
      </svg>

      <animated.button
        style={{
          cursor: "pointer",
          fontSize: "1em",
          fontWeight: 600,
          fontFamily: "system-ui, sans-serif",
          lineHeight: 1,
          border: "1px solid black",
          padding: "1em 2em",
          borderRadius: "2em",
          color: "black",
          background: "white",
        }}
        onClick={() => {
          if (morph.isPaused) {
            morph.resume();
          } else {
            morph.pause();
          }
        }}
      >
        Toggle morphing
      </animated.button>
    </div>
  );
};

export default SVGMorphingSamePaths;
