import * as React from "react";
import { useSpring, animated, config } from "react-spring";

const Chain = () => {
  const [flipped, flip] = React.useState(false);

  const springMemo = React.useMemo(() => {
    return {
      from: { xy: [0, 0], o: 0 },
      to: [
        {
          o: 1,
          xy: [Math.random() * 400, Math.random() * 400],
        },
        {
          o: 0,
          xy: [Math.random() * 400, Math.random() * 400],
        },
        {
          o: 1,
          xy: [0, 0],
        },
      ],
      reverse: flipped,
      onRest: () => {
        flip(!flipped);
      },
      config: { ...config.molasses, mass: 10 },
    };
  }, [flipped]);

  const { xy, o } = useSpring(springMemo);

  return (
    <>
      <animated.div
        style={{
          width: "100px",
          height: "100px",
          borderRadius: "50%",
          display: "grid",
          placeContent: "center",
          background: xy.to(
            (x) =>
              `radial-gradient(circle at 25% 25%, whitesmoke 10%, transparent), hsl(${x}deg 50% 50%)`
          ),
          position: "relative",
          zIndex: 1,
          transform: xy.to((x, y) => `translate(${x}px, ${y}px`),
        }}
      >
        {xy.to((x, y) => `chain (${x.toFixed(0)}, ${y.toFixed(0)})`)}
      </animated.div>

      <animated.div
        style={{
          width: "100px",
          height: "100px",
          display: "grid",
          placeContent: "center",
          borderRadius: "50%",
          backgroundColor: o.to({
            range: [0, 0.5, 1],
            output: ["red", "green", "blue"],
          }),
          position: "relative",
        }}
      >
        chain
      </animated.div>
    </>
  );
};

export default Chain;
