import React from "react";
import { useSpring, animated, config } from "react-spring";
import { interpolate } from "flubber";

const paths = [
  // star
  "M143 0L176.677 103.647H285.658L197.491 167.705L231.168 271.353L143 207.295L54.8322 271.353L88.5093 167.705L0.341522 103.647H109.323L143 0Z",
  // rectangle
  "M0 0H234V234H0V0Z",
  // circle
  "M234 117C234 181.617 181.617 234 117 234C52.3827 234 0 181.617 0 117C0 52.3827 52.3827 0 117 0C181.617 0 234 52.3827 234 117Z",
  // close
  "M399 269H0V224.667H399V269ZM399 158.167H0V113.833H399V158.167ZM399 47.3333H0V3H399V47.3333Z",
  // bars
  "M232.982 136.042L327.982 41.0416C332.49 36.5409 335.026 30.4335 335.032 24.0628C335.038 17.6922 332.512 11.5803 328.012 7.07163C323.511 2.56295 317.403 0.0268434 311.033 0.0212173C304.662 0.0155913 298.55 2.54091 294.042 7.04163L199.042 102.042L104.042 7.04163C99.533 2.53295 93.4179 0 87.0416 0C80.6654 0 74.5503 2.53295 70.0416 7.04163C65.533 11.5503 63 17.6654 63 24.0416C63 30.4179 65.533 36.533 70.0416 41.0416L165.042 136.042L70.0416 231.042C65.533 235.55 63 241.665 63 248.042C63 254.418 65.533 260.533 70.0416 265.042C74.5503 269.55 80.6654 272.083 87.0416 272.083C93.4179 272.083 99.533 269.55 104.042 265.042L199.042 170.042L294.042 265.042C298.55 269.55 304.665 272.083 311.042 272.083C317.418 272.083 323.533 269.55 328.042 265.042C332.55 260.533 335.083 254.418 335.083 248.042C335.083 241.665 332.55 235.55 328.042 231.042L232.982 136.042Z",
  // bag
  "M266.396 99.2578C264.659 97.5075 262.591 96.1195 260.313 95.174C258.035 94.2286 255.591 93.7446 253.125 93.75H215.625V84.375C215.625 66.9702 208.711 50.2782 196.404 37.9711C184.097 25.664 167.405 18.75 150 18.75C132.595 18.75 115.903 25.664 103.596 37.9711C91.289 50.2782 84.375 66.9702 84.375 84.375V93.75H46.875C41.9022 93.75 37.1331 95.7254 33.6167 99.2418C30.1004 102.758 28.125 107.527 28.125 112.5V239.062C28.125 261.914 47.4609 281.25 70.3125 281.25H229.688C240.741 281.253 251.357 276.932 259.266 269.209C263.245 265.412 266.415 260.848 268.582 255.793C270.749 250.737 271.869 245.295 271.875 239.795V112.5C271.883 110.04 271.402 107.603 270.462 105.33C269.522 103.057 268.14 100.993 266.396 99.2578V99.2578ZM103.125 84.375C103.125 71.943 108.064 60.0201 116.854 51.2294C125.645 42.4386 137.568 37.5 150 37.5C162.432 37.5 174.355 42.4386 183.146 51.2294C191.936 60.0201 196.875 71.943 196.875 84.375V93.75H103.125V84.375Z",
  // airplane
  "M271.875 190.652L271.875 206.25C271.874 207.817 271.481 209.359 270.731 210.735C269.98 212.111 268.897 213.277 267.58 214.126C266.263 214.976 264.753 215.481 263.19 215.596C261.627 215.712 260.06 215.434 258.633 214.787L175.283 177.018L174.023 233.73L199.084 254.414C204.041 258.357 206.25 261.504 206.25 269.531L206.25 280.031C206.304 281.694 205.955 283.345 205.235 284.844C204.515 286.343 203.444 287.647 202.113 288.645C200.232 290.039 197.045 291.41 192.498 290.074L150.896 278.461C150.586 278.373 150.275 278.268 149.971 278.15C149.942 278.145 149.912 278.145 149.883 278.15C149.578 278.271 149.267 278.375 148.951 278.461L107.086 290.086C102.627 291.346 99.5098 289.969 97.6758 288.586C96.4437 287.657 95.4466 286.453 94.7648 285.069C94.083 283.685 93.7354 282.16 93.75 280.617L93.75 269.531C93.75 263.537 96.4395 257.719 100.781 254.297L125.42 234.041L124.576 177.018L41.373 214.775C39.946 215.423 38.3794 215.702 36.8166 215.588C35.2537 215.474 33.7444 214.969 32.4267 214.121C31.1089 213.273 30.0248 212.109 29.2733 210.734C28.5218 209.359 28.127 207.817 28.125 206.25L28.125 190.482C28.1692 188.283 28.7084 186.121 29.7025 184.158C30.6965 182.195 32.12 180.482 33.8672 179.145L122.93 105.873L122.039 72.0234C121.904 69.5449 121.857 62.6777 121.857 61.0898C121.875 28.7109 132.387 9.37499 150 9.37499C155.543 9.37499 165.82 11.5898 172.359 26.4082C176.227 35.1562 178.184 46.8281 178.184 61.1016C178.184 62.6719 178.137 69.5215 178.002 72.0351L177.1 105.879L266.162 179.332C267.902 180.67 269.318 182.383 270.307 184.342C271.296 186.301 271.832 188.458 271.875 190.652V190.652Z",
];

const SVGMorphing = () => {
  const [morphFlipped, flipMorph] = React.useState(false);
  const { morph } = useSpring({
    from: { morph: 0 },
    morph: 1,
    reverse: morphFlipped,
    onRest: () => flipMorph(!morphFlipped),
    config: config.slow,
  });

  const interpolator = interpolate(paths[0], paths[6], {
    maxSegmentLength: 10,
  });
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        maxWidth: "350px",
        alignItems: "center",
      }}
    >
      <svg
        // width="350"
        height="316"
        viewBox="-30 0 350 316"
        // width="286"
        // height="272"
        // viewBox="0 0 286 272"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.path
          d={morph.to(interpolator)}
          fill="url(#paint0_linear_2:4)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2:4"
            x1="21"
            y1="-32"
            x2="244"
            y2="279.5"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F9AF20" />
            <stop offset="1" stopColor="#FF6060" />
          </linearGradient>
        </defs>
      </svg>

      <animated.button
        style={{
          cursor: "pointer",
          fontSize: "1em",
          fontWeight: 600,
          fontFamily: "system-ui, sans-serif",
          lineHeight: 1,
          border: "1px solid black",
          padding: "1em 2em",
          borderRadius: "2em",
          color: "black",
          background: "white",
        }}
        onClick={() => {
          if (morph.isPaused) {
            morph.resume();
          } else {
            morph.pause();
          }
        }}
      >
        Toggle morphing
      </animated.button>
    </div>
  );
};

export default SVGMorphing;
