import * as React from "react";
import { useSpring, animated, config } from "react-spring";

import { useCursorState } from "../context/cursorStateContext";
import { useCursorPosition } from "../context/cursorPositionContext";

const FollowMouse: React.FunctionComponent<{ diameter: number }> = ({
  diameter = 100,
}) => {
  const { cursorState, setCursorState } = useCursorState();
  const { x, y } = useCursorPosition();

  const radius = diameter / 2;

  const [follow, setFollow] = React.useState(false);
  const mouseStyles = useSpring({
    mousexy: follow
      ? [Number(x) + radius, Number(y) - diameter]
      : [-diameter, -diameter],
    color: cursorState === "clickable" ? "red" : "purple",
    config: { ...config.slow },
  });

  return (
    <>
      <animated.div
        style={{
          position: "fixed",
          display: "grid",
          placeContent: "center",
          opacity: 0.9,
          top: 0,
          left: 0,
          width: `${diameter}px`,
          height: `${diameter}px`,
          pointerEvents: "none",
          userSelect: "none",
          borderRadius: "50%",
          color: "white",
          background: mouseStyles.color.to(
            (color) => `radial-gradient(circle at 25% 25%, ${color}, blue)`
          ),
          transform: mouseStyles.mousexy.to(
            (x, y) => `translate(${x}px, ${y}px)`
          ),
        }}
      >
        {cursorState === "clickable" ? "CLICK!" : ""}
      </animated.div>

      <button
        style={{
          cursor: "pointer",
          fontSize: "1em",
          fontWeight: 600,
          fontFamily: "system-ui, sans-serif",
          lineHeight: 1,
          border: "1px solid black",
          padding: "1em 2em",
          borderRadius: "2em",
          color: "black",
          background: "white",
        }}
        onMouseEnter={() => setCursorState("clickable")}
        onMouseLeave={() => setCursorState("normal")}
        onClick={() => setFollow((prev) => !prev)}
      >
        {follow ? "Unfollow" : "Follow"} mouse
      </button>
    </>
  );
};

export default FollowMouse;
