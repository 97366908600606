import * as React from "react";

import SimpleLoop from "./components/SimpleLoop";
import Chain from "./components/Chain";
import ClickPalindromeAnimation from "./components/ClickPalindromeAnimation";
import ClickPalindromeAnimationWithAPI from "./components/ClickPalindromeAnimationWithAPI";
import CurvedMotion from "./components/CurvedMotion";
// import SVGMorphing from "./components/SVGMorphing";
import SVGMorphingSamePaths from "./components/SVGMorphingSamePaths";
import SVGMorphing from "./components/SVGMorphing";
import HoverStyles from "./components/HoverStyles";
import FollowMouse from "./components/FollowMouse";
// import Bounce from "./components/Bounce";

import "./App.css";

function App() {
  return (
    <div className="App" style={{ minHeight: "100vh" }}>
      <SimpleLoop />

      {/* Random chain flipped + color interpolation */}
      <Chain />

      <ClickPalindromeAnimation />

      <ClickPalindromeAnimationWithAPI />

      <FollowMouse diameter={50} />

      <CurvedMotion />

      {/* <Bounce /> */}

      <SVGMorphing />

      <SVGMorphingSamePaths />

      <HoverStyles />
    </div>
  );
}

export default App;
