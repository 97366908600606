import * as React from "react";
import { useSpring, animated, config } from "react-spring";
import { useCursorState } from "../context/cursorStateContext";

const ClickPalindromeAnimationWithAPI = () => {
  const { setCursorState } = useCursorState();
  const palindromeSpringApi = React.useCallback(
    () => ({
      from: { x: 0 },
      config: { ...config.wobbly },
    }),
    []
  );
  const [apiStyles, api] = useSpring(palindromeSpringApi);

  return (
    <animated.div
      style={{
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        display: "grid",
        placeContent: "center",
        cursor: "pointer",
        background: "radial-gradient(circle at 25% 25%, yellow, orangered)",
        transform: apiStyles.x
          .to({ range: [0, 0.5, 1], output: [100, 150, 100] })
          .to((x) => `scale(${x}%)`),
      }}
      onClick={() => {
        api.start({
          x: apiStyles.x.get() >= 1 ? 0 : 1,
        });
      }}
      onMouseEnter={() => setCursorState("clickable")}
      onMouseLeave={() => setCursorState("normal")}
    >
      <span style={{ userSelect: "none" }}>Click me</span>
    </animated.div>
  );
};

export default ClickPalindromeAnimationWithAPI;
