import * as React from "react";

type CursorStates = "clickable" | "normal";
interface CursorStateValue {
  cursorState?: CursorStates;
  setCursorState: any;
}

const CursorStateContext = React.createContext<CursorStateValue | undefined>(
  undefined
);

interface CursorStateProviderProps {
  children: React.ReactNode;
}
export const CursorStateProvider: React.FunctionComponent<CursorStateProviderProps> =
  ({ children }) => {
    const [cursorState, setCursorState] =
      React.useState<CursorStates>("normal");

    return (
      <CursorStateContext.Provider value={{ cursorState, setCursorState }}>
        {children}
      </CursorStateContext.Provider>
    );
  };

export const useCursorState = (): CursorStateValue => {
  const context = React.useContext(CursorStateContext);
  if (!context)
    throw new Error(
      "useCursorState must be used within a CursorStateProvider."
    );

  return context;
};
