import * as React from "react";
import { useSpring, animated, config } from "react-spring";

const SimpleLoop = () => {
  const { size } = useSpring({
    from: { size: 0.5 },
    size: 0.8,
    loop: true,
    config: { ...config.wobbly, mass: 5 },
  });
  return (
    <animated.div
      style={{
        position: "relative",
        left: "100px",
        width: "100px",
        height: "100px",
        display: "grid",
        placeContent: "center",
        borderRadius: "50%",
        background: "radial-gradient(circle at 25% 25%, orange, orangered)",
        transform: size.to((size) => `scale(${size * 100}%)`),
      }}
    >
      simple loop
    </animated.div>
  );
};

export default SimpleLoop;
