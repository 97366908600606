import * as React from "react";
import { useSpring, animated, config } from "react-spring";

const CurvedMotion = () => {
  const [flipped, flip] = React.useState(false);

  const springMemo = React.useMemo(() => {
    return {
      from: { progression: 0 },
      progression: 1,
      reverse: flipped,
      onRest: () => flip(!flipped),
      config: { ...config.slow, tension: 180, friction: 100 },
    };
  }, [flipped]);
  const { progression } = useSpring(springMemo);

  const toTranslateXY = React.useCallback((x) => {
    return `translate(${x ** 3 * 1000}px, ${x * -500}px)`;
  }, []);

  return (
    <animated.div
      style={{
        width: "100px",
        height: "100px",
        borderRadius: "50%",
        background: "radial-gradient(circle at 25% 25%, yellow, 30%, blue)",
        transform: progression.to(toTranslateXY),
      }}
    ></animated.div>
  );
};

export default CurvedMotion;
