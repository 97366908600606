import * as React from "react";
import throttle from "lodash.throttle";

interface CursorPositionValue {
  x?: number;
  y?: number;
}

const CursorPositionContext = React.createContext<
  CursorPositionValue | undefined
>(undefined);

interface CursorPositionProviderProps {
  children: React.ReactNode;
}
export const CursorPositionProvider: React.FunctionComponent<CursorPositionProviderProps> =
  ({ children }) => {
    const [x, setX] = React.useState<number>(0);
    const [y, setY] = React.useState<number>(0);

    const onMouseMoveThrottled = React.useCallback(
      throttle((e) => {
        setX(e.clientX);
        setY(e.clientY);
      }, 100),
      []
    );

    return (
      <div onMouseMove={onMouseMoveThrottled}>
        <CursorPositionContext.Provider value={{ x, y }}>
          {children}
        </CursorPositionContext.Provider>
      </div>
    );
  };

export const useCursorPosition = (): CursorPositionValue => {
  const context = React.useContext(CursorPositionContext);
  if (!context)
    throw new Error(
      "useCursorPosition must be used within a CursorPositionProvider."
    );

  return context;
};
